import useSWR, { SWRConfiguration } from 'swr';
import { useEffect, useMemo, useRef } from 'react';
import { ApiError, ApiResponse, parsedEndpoint, Struct,  } from '@mtechvault/ams-types';
import { UserFetch } from '@mtechvault/ams-client-base';
import Logger from "../util/logger";

const logger = Logger.getLogger('useUser Hook');

const defaultOpts: Partial<SWRConfiguration> = {
    revalidateOnFocus: false,
    revalidateOnMount: false,
    dedupingInterval: 500,
};

type Props = Partial<SWRConfiguration>;

function useUser(opts: Props = {}) {
    const abortController = useRef<AbortController | null>(null);
    const memoOpts: Partial<SWRConfiguration> = useMemo(
        () => ({
            ...defaultOpts,
            ...opts,
        }),
        [
            opts,
            opts.use,
            opts.dedupingInterval,
            opts.suspense,
            opts.refreshInterval,
            opts.isVisible,
            opts.isOnline,
            opts.isPaused,
            opts.fallback,
            opts.fallbackData,
            opts.compare,
            opts.errorRetryCount,
            opts.errorRetryInterval,
            opts.onErrorRetry,
            opts.onError,
            opts.onLoadingSlow,
            opts.onSuccess,
            opts.revalidateOnFocus,
            opts.revalidateIfStale,
            opts.revalidateOnMount,
            opts.revalidateOnReconnect,
        ]
    );

    const { data, isValidating, mutate, error } = useSWR<
        ApiResponse<Struct.User> | null,
        ApiError
    >(parsedEndpoint('userFetch'), UserFetch(abortController), memoOpts);

    const isLoggedIn = useMemo(
        () => typeof error === 'undefined' && typeof data?.data?.id === 'number',
        [data, error]
    );
    const isVerified = useMemo(
        () => typeof error === 'undefined' && data?.data?.verified === true,
        [data, error]
    );

    useEffect(() => {
        if (error && error.code !== -10010) {
            logger.error(error);
        }
    }, [error]);

    return {
        user: error ? null : data?.data ? data.data : null,
        isValidating,
        isLoggedIn,
        isVerified,
        mutate,
        error: error as ApiError,
    };
}

export type UseUserProps = Props;
export default useUser;
