import useSWR, { SWRConfiguration } from 'swr';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ApiResponse, Pagination, parsedEndpoint, Struct } from '@mtechvault/ams-types';
import { useUniqueId, useKeyboard } from '@mtechvault/ams-client-base'
import useOrganization from './useOrganization';
import { OrganizationUsersListFetch } from '@mtechvault/ams-client-base';

type InternalPage = Pick<Pagination<Struct.OrganizationUser>, 'after' | 'before'>;

type Props = {
    pagination: Omit<
        Pagination<Struct.OrganizationUser>,
        'after' | 'before' | 'page'
    > & {};
    config?: SWRConfiguration;
    enableKeyboard?: boolean;
};

function useOrganizationUsers(props: Props) {
    const { pagination: otherProps, enableKeyboard, config } = props;
    const internalId = useUniqueId('paged-organization-users');
    const { current: organization } = useOrganization();
    const abortController = useRef<AbortController | null>(null);
    const pageIndex = useRef(0);
    const pageRefs = useRef<Array<Pagination>>([{}]);
    const [internalPage, setInternalPage] = useState<InternalPage>({});
    const swrKey = useMemo(() => {
        return [
            parsedEndpoint('organizationUsers', {
                organization: organization?.id,
                key: otherProps.key,
                desc: otherProps.desc,
                like: otherProps.like,
                limit: otherProps.limit,
                after: internalPage.after,
                before: internalPage.before,
            }),
        ];
    }, [
        organization?.id,
        otherProps.key,
        otherProps.desc,
        otherProps.like,
        otherProps.limit,
        internalPage.after,
        internalPage.before,
    ]);
    const memoOpts: SWRConfiguration = useMemo(() => {
        return { ...(config || {}) };
    }, [config]);

    const { data, isValidating, mutate, error } = useSWR<
        ApiResponse<Struct.OrganizationUser[]>
    >(
        organization ? swrKey : null,
        OrganizationUsersListFetch(abortController),
        memoOpts
    );

    const hasNext = useMemo(() => {
        return (otherProps.limit || 10) <= (data?.data.length || 0);
    }, [otherProps.limit, data?.data?.length]);

    const hasPrev = useMemo(() => pageIndex.current > 0, [swrKey]);

    const next = useCallback(() => {
        pageIndex.current += 1;
        if (
            pageRefs.current.findIndex(
                (i: Pagination) =>
                    i.before === (otherProps.desc ? data?.meta?.last : undefined) &&
                    i.after === (!otherProps.desc ? data?.meta?.last : undefined)
            ) === -1
        ) {
            pageRefs.current.push({
                ...prev,
                before: otherProps.desc ? data?.meta?.last : undefined,
                after: !otherProps.desc ? data?.meta?.last : undefined,
            });
        }

        setInternalPage(() => (pageRefs.current[pageIndex.current] as never) || {});
    }, [setInternalPage, data?.meta?.last, isValidating]);

    const prev = useCallback(() => {
        if (pageIndex.current <= 0) return;

        pageIndex.current -= 1;
        setInternalPage(() => (pageRefs.current[pageIndex.current] as never) || {});
    }, [setInternalPage, isValidating]);

    const keyboardHandler = useCallback(
        (evt) => {
            if (evt.key === 'ArrowRight') {
                if (hasNext) next();
            }
            if (evt.key === 'ArrowLeft') {
                if (hasPrev) prev();
            }
        },
        [hasNext, hasPrev, next, prev]
    );

    useKeyboard(internalId, enableKeyboard ? keyboardHandler : null);

    useEffect(() => {
        pageIndex.current = 0;
        pageRefs.current = [{}];
        setInternalPage(() => (pageRefs.current[pageIndex.current] as never) || {});
    }, [otherProps.desc, otherProps.key, otherProps.like, otherProps.limit]);

    useEffect(
        () => () => {
            abortController.current?.abort();
        },
        []
    );

    return {
        data: data?.data,
        meta: data?.meta,
        type: data?.type,
        code: data?.code,
        isValidating,
        mutate,
        error,
        hasNext,
        hasPrev,
        next,
        prev,
        abortController: abortController.current,
    };
}

export type UseOrganizationUsersProps = Props;
export default useOrganizationUsers;
