import { useCallback, useContext } from 'react';
import { SocketContext, SocketContextActions } from '../context/organization/socket';
import { Struct } from '@mtechvault/ams-types';

type Props = {
    jwt?: string;
};

// deviceUid: string;
// organization: number;
// hwid: string;
// code: string;
// jwt: string;

type PairBody = {
    hwid: string;
    code: string;
    name: string;
};

type Actions = SocketContextActions & {
    checkPair: (
        jwt: string,
        organization: number,
        code: string
    ) => Promise<{ hwid: string }>;
    performPair: (
        jwt: string,
        organization: number,
        body: PairBody
    ) => Promise<Pick<Struct.Device, 'uid' | 'name'>>;
    monitor: (jwt: string, organization: number, uid: string) => Promise<boolean>;
    unmonitor: (jwt: string, organization: number, uid: string) => Promise<boolean>;
};

function useSocket(props: Props = {}) {
    const { state, actions } = useContext(SocketContext);

    const checkPair: Actions['checkPair'] = useCallback((jwt, organization, code) => {
        return new Promise((resolve, reject) => {
            state.io.emit(
                'client.checkPair',
                {
                    jwt,
                    organization,
                    code,
                },
                (err, res) => {
                    if (err) reject(err);
                    else resolve(res);
                }
            );
        });
    }, []);

    const performPair: Actions['performPair'] = useCallback((jwt, organization, body) => {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                reject(new Error('pair timeout exceeded'));
                state.io.off('client.pairCompleted', onComplete);
            }, 60000);

            const onComplete = (err, res) => {
                clearTimeout(timeout);
                state.io.off('client.pairCompleted', onComplete);
                if (err) reject(err);
                else resolve(res);
            };
            state.io.on('client.pairCompleted', onComplete);
            state.io.emit(
                'client.pair',
                {
                    jwt,
                    organization,
                    ...body,
                },
                (err) => {
                    if (err) {
                        clearTimeout(timeout);
                        reject(err);
                        state.io.off('client.pairCompleted', onComplete);
                    }
                }
            );
        });
    }, []);

    const monitor: Actions['monitor'] = useCallback(
        (jwt, organization, deviceUid) => {
            // state.io.emit('client.monitor.enable', { jwt, organization, deviceUid })
            return new Promise((resolve, reject) => {
                const parsed = (evt: string) => `${deviceUid}-${evt}`;

                const timeout = setTimeout(() => {
                    reject(new Error('Device monitor timeout exceeded'));
                    state.io.off(parsed('client.monitor.error'), onError);
                    state.io.off(parsed('client.monitor.enabled'), onEnabled);
                }, 30000);

                const onEnabled = () => {
                    resolve(true);
                    state.io.off(parsed('client.monitor.error'));
                    clearTimeout(timeout);
                };

                const onError = (err) => {
                    reject(err);
                    state.io.off(parsed('client.monitor.enabled'));
                    clearTimeout(timeout);
                };

                state.io.once(parsed('client.monitor.error'), onError);
                state.io.once(parsed('client.monitor.enabled'), onEnabled);
                state.io.emit('client.monitor.enable', {
                    jwt,
                    organization,
                    deviceUid,
                });
            });
        },
        [state.io]
    );

    const unmonitor: Actions['unmonitor'] = useCallback(
        (jwt, organization, deviceUid) => {
            // state.io.emit('client.monitor.enable', { jwt, organization, deviceUid })
            return new Promise((resolve, reject) => {
                const parsed = (evt: string) => `${deviceUid}-${evt}`;

                const timeout = setTimeout(() => {
                    reject(new Error('Device unmonitor timeout exceeded'));
                    state.io.off(parsed('client.monitor.error'), onError);
                    state.io.off(parsed('client.monitor.disabled'), onDisabled);
                }, 30000);

                const onDisabled = () => {
                    resolve(true);
                    state.io.off(parsed('client.monitor.error'));
                    clearTimeout(timeout);
                };

                const onError = (err) => {
                    reject(err);
                    state.io.off(parsed('client.monitor.disabled'));
                    clearTimeout(timeout);
                };

                state.io.once(parsed('client.monitor.error'), onError);
                state.io.once(parsed('client.monitor.disabled'), onDisabled);
                state.io.emit('client.monitor.disable', {
                    jwt,
                    organization,
                    deviceUid,
                });
            });
        },
        [state.io]
    );

    return {
        io: state.connected ? state.io : null,
        connected: state.connected,
        connecting: state.connecting,
        error: state.connectError,
        actions: {
            ...actions,
            checkPair,
            performPair,
            monitor,
            unmonitor,
        } as Actions,
        // io: connected ? io : null,
        // connected,
        // error,
        // connect
    };
}

export type UseSocketProps = Props;
export default useSocket;
