import React, { useEffect } from 'react';
import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterLuxon';
import {BrowserRouter} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import AppRouter from './routers/app';
import { buildTheme } from './theme';
import { LayoutProvider } from './layout';
import { LoadingFallback, CustomThemeProvider } from '@mtechvault/ams-client-base';

type Props = {
    preloader?: HTMLElement | null;
};

function togglePreloader(preloader: HTMLElement) {
    preloader.classList.add('done');
    setTimeout(() => {
        preloader.remove();
    }, 400);
}


function App(props: Props) {
    const { preloader } = props;
    const { t } = useTranslation('common');

    useEffect(() => {
        if (preloader) togglePreloader(preloader);
    }, []);

    return (
        <LocalizationProvider dateAdapter={DateAdapter}>
            <Helmet
                title={t('htmlTitle.default')}
                defaultTitle={t('appName')}
                titleTemplate={t('htmlTitle.template')}
            />
            <CustomThemeProvider builder={buildTheme}>
                <CssBaseline />
                <React.Suspense fallback={<LoadingFallback />}>
                <BrowserRouter>
                        <LayoutProvider layout={'minimal'}>
                            <SnackbarProvider maxSnack={3}>
                                <AppRouter />
                            </SnackbarProvider>
                        </LayoutProvider>
                </BrowserRouter>
                </React.Suspense>
            </CustomThemeProvider>
        </LocalizationProvider>
    );
}

export default App;
