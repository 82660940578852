import React, {
    ReactNode,
    Reducer,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
} from 'react';
import { Socket } from 'socket.io-client';
import { SOCKET_IO } from '../../conf';
import Logger from "../../util/logger";

const logger = Logger.getLogger('Socket');

type Props = {
    children: ReactNode;
};

type R = unknown;

type SocketContextState = {
    io: Socket;
    connected: boolean;
    connecting?: boolean;
    connectError: Error | string | null;
};

export type SocketContextActions = {
    connect: (reconnect?: boolean) => Promise<void>;
    disconnect: () => void;
};

// eslint-disable-next-line
enum Actions {
    CONNECTED = 'Socket/CONNECTED',
    CONNECT_ERROR = 'Socket/CONNECT_ERROR',
    CONNECTING = 'Socket/CONNECTING',
    RECONNECTING = 'Socket/RECONNECTING',
    DISCONNECTED = 'Socket/DISCONNECTED',
}

type DispatchActions = {
    [S in keyof SocketContextActions]: SocketContextActions[S];
};

type ReducerAction = {
    type: Actions;
    payload?: any;
};

type SocketContextWrapper = {
    actions: SocketContextActions;
    state: SocketContextState;
};

export const SocketContext = React.createContext<SocketContextWrapper>({} as never);
SocketContext.displayName = 'SocketContext';

const reducer: Reducer<SocketContextState, ReducerAction> = (state, action) => {
    switch (action.type) {
        case Actions.CONNECTING:
            return {
                ...state,
                connecting: true,
                connected: state.io.connected,
                connectError: null,
            };
        case Actions.RECONNECTING:
            return {
                ...state,
                connecting: true,
                connected: state.io.connected,
                connectError: null,
                // error: action.payload
            };
        case Actions.CONNECTED:
            return {
                ...state,
                connected: state.io.connected,
                connecting: false,
                connectError: null,
            };
        case Actions.DISCONNECTED:
            return {
                ...state,
                connected: state.io.connected,
                connecting: false,
                connectError: null,
            };
        case Actions.CONNECT_ERROR:
            return {
                ...state,
                connected: state.io.connected,
                connecting: false,
                connectError: action.payload,
            };
        default:
            return state;
    }
};

function useSocketReducer(initialState: SocketContextState) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const socketConnect: (exec?: boolean) => Promise<void> = useCallback(
        (exec = true) => {
            return new Promise((resolve, reject) => {
                if (state.io.connected) {
                    resolve();
                    return;
                }

                let timeout: NodeJS.Timeout = setTimeout(() => {
                    state.io.disconnect();
                    reject(new Error('Socket Connect Timeout'));
                }, 60000);

                const connected = () => {
                    resolve();
                    clearTimeout(timeout);
                    state.io.off('connect', connected);
                    state.io.off('connect_error', connectError);
                };

                let retryCount = 1;
                const connectError = (err: Error) => {
                    if (retryCount < 6) {
                        // eslint-disable-next-line
                        logger.debug(`Attempt #${retryCount++} to connect to socket`);
                        // setTimeout(() => {
                        //     state.io.connect();
                        // }, 500);
                        return;
                    }

                    state.io.off('connect', connected);
                    state.io.off('connect_error', connectError);
                    state.io.disconnect();
                    reject(err);
                    clearTimeout(timeout);
                };

                state.io.on('connect', connected);
                state.io.on('connect_error', connectError);

                if (exec) {
                    // state.io.connect();
                    // timeout somehow prevents duplicate connections
                    setTimeout(() => state.io.connect(), 10);
                }
            });
        },
        [state.connected]
    );

    const actions: DispatchActions = useMemo(
        () => ({
            connect: async () => {
                logger.debug('attempting to connect to socket');

                dispatch({ type: Actions.CONNECTING });

                const err = await socketConnect().catch((error: Error) => error);

                if (err instanceof Error) {
                    logger.error({
                        message: 'Unable to connect to socket',
                        error: err,
                    });
                    dispatch({
                        type: Actions.CONNECT_ERROR,
                        payload: err,
                    });
                    return;
                }

                logger.debug('Connected to socket');
                dispatch({ type: Actions.CONNECTED });
            },
            disconnect: () => {
                logger.debug('Socket disconnect fired');
                state.io.disconnect();
                dispatch({ type: Actions.DISCONNECTED });
            },
        }),
        [socketConnect]
    );

    useEffect(() => {
        const disconnect = async (reason: string) => {
            dispatch({
                type: Actions.RECONNECTING,
                payload: reason,
            });

            logger.debug('Attempting to reconnect to socket');
            const err = await socketConnect(false).catch((error) => error);

            if (err instanceof Error) {
                logger.error({
                    message: 'Unable to reconnect to socket',
                    error: err,
                });
                dispatch({
                    type: Actions.CONNECT_ERROR,
                    payload: err,
                });
                return;
            }

            logger.debug('Reconnected to socket');
            dispatch({ type: Actions.CONNECTED });
        };

        if (state.io.connected && state.connected) {
            state.io.on('disconnect', disconnect);
        }

        return () => {
            state.io.off('disconnect', disconnect);
        };
    }, [state.io.connected, state.connected, socketConnect]);

    return {
        state,
        actions,
    };
}

function SocketProvider(props: Props) {
    const { children } = props;
    const contextState = useSocketReducer({
        io: SOCKET_IO,
        connected: false,
        connectError: null,
    });

    useEffect(
        () => () => {
            if (contextState.state.connected) {
                logger.debug('disconnecting socket via organization exit');
                contextState.actions.disconnect();
            }
        },
        []
    );

    return (
        <SocketContext.Provider value={contextState}>{children}</SocketContext.Provider>
    );
}

export type SocketProviderProps = Props;
export default SocketProvider;
