import useSWR, { SWRConfiguration } from 'swr';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useOrganization from './useOrganization';
import { ApiResponse, JWTToken, Pagination, parsedEndpoint, Struct } from '@mtechvault/ams-types';
import { LocationListFetch } from '@mtechvault/ams-client-base';

type InternalPage = Pick<Pagination<Struct.Location>, 'after' | 'before'>;
type Props = {
    pagination: Omit<Pagination<Struct.Location>, 'after' | 'before' | 'page'> & {
        listMode?: 'select';
    };
    config?: SWRConfiguration;
    // organization?: number;
};
function usePagedLocations(props: Props = { pagination: {} }) {
    const { pagination: otherProps, config } = props;
    const { current: organization } = useOrganization();
    const abortController = useRef<AbortController | null>(null);
    const pageIndex = useRef<number>(0);
    const pageRefs = useRef<Array<Pagination>>([{}]);
    const [internalPage, setInternalPage] = useState<InternalPage>({});

    const swrKey = useMemo(() => {
        return parsedEndpoint('locationGetMany', {
            organization: organization?.id,
            key: otherProps.key,
            desc: otherProps.desc,
            like: otherProps.like,
            limit: otherProps.limit,
            listMode: otherProps.listMode,
            after: internalPage.after,
            before: internalPage.before,
        });
    }, [
        organization?.id,
        otherProps.key,
        otherProps.desc,
        otherProps.like,
        otherProps.limit,
        otherProps.listMode,
        internalPage.after,
        internalPage.before,
    ]);

    // const { data, isValidating, mutate, error} = useSWR(swrKey, fetcher, {
    const { data, isValidating, mutate, error } = useSWR<ApiResponse<Struct.Location[]>>(
        organization ? swrKey : null,
        LocationListFetch(abortController),
        {
            ...(config || {}),
            // revalid
        }
    );

    const hasNext = useMemo(() => {
        return (otherProps.limit || 10) <= (data?.data.length || 0);
    }, [otherProps.limit, data?.data?.length]);

    const hasPrev = useMemo(() => pageIndex.current > 0, [swrKey]);

    const next = useCallback(() => {
        pageIndex.current += 1;
        if (
            pageRefs.current.findIndex(
                (i: Pagination) =>
                    i.before === (otherProps.desc ? data?.meta?.last : undefined) &&
                    i.after === (!otherProps.desc ? data?.meta?.last : undefined)
            ) === -1
        ) {
            pageRefs.current.push({
                ...prev,
                before: otherProps.desc ? data?.meta?.last : undefined,
                after: !otherProps.desc ? data?.meta?.last : undefined,
            });
        }
        setInternalPage(() => (pageRefs.current[pageIndex.current] as never) || {});
    }, [setInternalPage, data?.meta?.last]);

    const prev = useCallback(() => {
        if (pageIndex.current <= 0) return;
        pageIndex.current -= 1;
        setInternalPage(() => (pageRefs.current[pageIndex.current] as never) || {});
    }, [setInternalPage]);

    useEffect(() => {
        pageIndex.current = 0;
        pageRefs.current = [{}];
        setInternalPage(() => (pageRefs.current[pageIndex.current] as never) || {});
    }, [otherProps.desc, otherProps.key, otherProps.like, otherProps.limit]);

    return {
        data: data?.data,
        meta: data?.meta,
        type: data?.type,
        code: data?.code,
        isValidating,
        error,
        next,
        hasPrev,
        hasNext,
        prev,
        mutate,
        abortController: abortController.current,
    };
}

export type UsePagedLocationsProps = Props;
export default usePagedLocations;
