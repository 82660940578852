import { useMemo } from 'react';
import useSWR, { SWRConfiguration } from 'swr';
import useOrganization from './useOrganization';
import { ApiResponse, parsedEndpoint, Struct } from '@mtechvault/ams-types';
import { SignageFetch } from '@mtechvault/ams-client-base';

type Props = Partial<SWRConfiguration> & {
    id?: number | null;
    shouldFetch?: boolean;
    content?: boolean;
};
function useSignage(props: Props = {}) {
    const { shouldFetch, content, ...swrOpts } = props;
    const { current: organization } = useOrganization();
    const swrKey = useMemo(() => {
        return parsedEndpoint('signageGetOne', {
            organization: organization?.id,
            id: props?.id,
        });
    }, [props?.id, organization?.id]);
    const memoOpts = useMemo(() => {
        return { ...swrOpts };
    }, [swrOpts]);
    const { data, isValidating, mutate, error } = useSWR<ApiResponse<Struct.Signage>>(
        shouldFetch && props?.id ? swrKey : null,
        SignageFetch,
        memoOpts
    );

    return {
        data: data?.data,
        meta: data?.meta,
        type: data?.type,
        code: data?.code,
        isValidating,
        mutate,
        error,
    };
}

export type UseSignageProps = Props;
export default useSignage;
