import { FormatFunction, InterpolationOptions } from 'i18next';
import { millisToDisplay } from '@mtechvault/ams-client-base';

export const format: FormatFunction = function formatFunction(value, fmt: any, lng) {
    if (fmt === 'firstUpper') {
        return value.charAt(0).toUpperCase() + value.substr(1);
    }

    // if (format === '')

    if (fmt === 'duration') {
        if (typeof value === 'number') {
            return millisToDisplay(value);
        }
    }
    return value;
};

export default {
    format,
} as InterpolationOptions;
