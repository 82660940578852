import {useLocation, useMatch} from 'react-router-dom';
import {useEffect, useMemo, useRef} from 'react';
import useUser from './useUser';
import useSWR, {SWRConfiguration} from "swr";
import {ApiResponse, parsedEndpoint, Struct} from "@mtechvault/ams-types";
import {OrganizationFetchMany, OrganizationFetchOne} from '@mtechvault/ams-client-base';

type Params = {
    organizationId: string;
};

type Props = {
    organization?: number;
};

function useOrganization(props: Props = {}) {
    const matcher = useMatch('/o/:organizationId/*')

    const organizationId: number | null = useMemo(() => {
        if (!!matcher && matcher.params.organizationId) {
            return Number.parseInt(matcher.params.organizationId, 10)
        }
        return null
    }, [matcher])

    const organizations = useOrganizationsSWR();
    const currentOrganization = useTargetOrganizationSWR({ id: organizationId })

    return {
        isValidating: organizations.isValidating || currentOrganization.isValidating,
        isCurrentValidating: currentOrganization.isValidating,
        error: organizations.error || currentOrganization.error,
        organizations: organizations.data?.data || undefined,
        current: currentOrganization.data?.data || undefined,
        refreshOrganizations: organizations.mutate,
        refreshCurrent: currentOrganization.mutate,
        abortCurrent: currentOrganization.abortController,
        abortList: organizations.abortController
    }
}

export function useOrganizationsSWR() {
    const abortController = useRef<AbortController | null>(new AbortController());
    const memoOpts: Partial<SWRConfiguration> = useMemo(() => ({
        revalidateIfStale: true,
        revalidateOnMount: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true
    }), []);
    const { data, isValidating, mutate, error } = useSWR<ApiResponse<Struct.OrganizationWithOrganizationUser[]>>(
        parsedEndpoint('organizationGetMany'),
        OrganizationFetchMany(abortController),
        memoOpts
    )

    return {
        data, isValidating, mutate, error, abortController
    }
}

export function useTargetOrganizationSWR({ id }: { id: number | null }) {
    const abortController = useRef<AbortController | null>(new AbortController());
    const memoOpts: Partial<SWRConfiguration> = useMemo(() => ({
        revalidateIfStale: true,
        revalidateOnMount: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true,
        refreshInterval: 60000,
    }), []);
    const memoEndpoint = useMemo(() => (parsedEndpoint('organizationGetById', { organization: id })), [id]);
    const { data, isValidating, mutate, error } = useSWR<ApiResponse<Struct.OrganizationWithOrganizationUser>>(
        [memoEndpoint],
        OrganizationFetchOne(abortController),
        memoOpts
    )

    return {
        data, isValidating, mutate, error, abortController
    }
}

export type UseOrganizationProps = Props;
export default useOrganization;
