import { JL } from 'jsnlog';

export type LoggerOpts = JL.JSNLogAppenderOptions & {
    forceLevel?: boolean;
};

export default class Logger {
    private static LOGGERS: {
        [s: string]: Logger;
    } = {};

    public readonly name: string;

    private readonly jsn: JL.JSNLogLogger;

    constructor(name: string, opts?: LoggerOpts) {
        this.name = name;
        this.jsn = JL(name);
        this.jsn.setOptions({
            appenders: this.getAppenders(opts),
            level: JL.getAllLevel(),
        });
    }

    private getAppenders(opts: LoggerOpts = {}) {
        const consoleAppender = JL.createConsoleAppender(`${this.name}-console`);
        if (consoleAppender.setOptions) {
            consoleAppender.setOptions({
                level: JL.getInfoLevel(),
                storeInBufferLevel: JL.getDebugLevel(),
                sendWithBufferLevel: JL.getInfoLevel(),
                bufferSize: 10,
                ...opts,
            });
        }
        const ajaxAppender = JL.createAjaxAppender(`${this.name}-ajax`);
        if (ajaxAppender.setOptions) {
            const level = opts.level;
            delete opts.level;

            ajaxAppender.setOptions({
                level: opts.forceLevel ? level : Math.max(level || 0, JL.getInfoLevel()),
                storeInBufferLevel: JL.getDebugLevel(),
                sendWithBufferLevel: JL.getWarnLevel(),
                bufferSize: 10,
                batchSize: 10,
                maxBatchSize: 100,
                batchTimeout: 30 * 1000,
                url: '/api/v2/logging/webapp',
                // beforeSend: (req, data) => {
                //     // data.hwid = global.window.hwid || 'N/A'
                //     // data.session = global.window.sessionUid || 'N/A'
                //     // if (data.lg.length > 0) {
                //     //     data.lg = data.lg.map((item: any) => {
                //     //         return item;
                //     //     })
                //     // }
                // },
                ...opts,
            });
        }

        return [consoleAppender, ajaxAppender];
    }

    public static getLogger(name: string, opts?: LoggerOpts) {
        if (typeof Logger.LOGGERS[name] === 'undefined') {
            Logger.LOGGERS[name] = new Logger(name, opts);
        }
        return Logger.LOGGERS[name];
    }

    debug(logObject: any): Logger {
        this.jsn.debug(Logger.convert(logObject));
        return this;
    }

    error(logObject: any): Logger {
        this.jsn.error(Logger.convert(logObject));
        return this;
    }

    fatal(logObject: any): Logger {
        this.jsn.fatal(Logger.convert(logObject));
        return this;
    }

    fatalException(logObject: any, e: any): Logger {
        this.jsn.fatalException(Logger.convert(logObject), e);
        return this;
    }

    info(logObject: any): Logger {
        this.jsn.info(Logger.convert(logObject));
        return this;
    }

    log(level: number, logObject: any, e?: any): Logger {
        this.jsn.log(level, Logger.convert(logObject), e);
        return this;
    }

    trace(logObject: any): Logger {
        this.jsn.trace(Logger.convert(logObject));
        return this;
    }

    warn(logObject: any): Logger {
        this.jsn.warn(Logger.convert(logObject));
        return this;
    }

    private static convert(logObject: any) {
        if (typeof logObject === 'object') {
            logObject = JSON.stringify(logObject, null, 2);
        }
        return logObject;
    }
}
