import useSWR, { SWRConfiguration } from 'swr';
import { useMemo, useRef } from 'react';
import { ApiResponse, parsedEndpoint, Struct } from '@mtechvault/ams-types';
import useOrganization from './useOrganization';
import {DeviceFetch, DevicesListFetch, PlaylistFetch} from '@mtechvault/ams-client-base';

type Props = Partial<SWRConfiguration> & {
    uid?: string | null;
    session?: boolean;
    playlist?: boolean;
    location?: boolean;
};
function useDevice(props: Props = {}) {
    const { uid: playlistId, location, playlist, session, ...swrOpts } = props;
    const { current: organization } = useOrganization();
    const abortController = useRef<AbortController | null>(null);
    const swrKey = useMemo(() => {
        return parsedEndpoint('deviceGetOne', {
            organization: organization?.id,
            uid: props?.uid,
            location,
            playlist,
            session,
        });
    }, [organization?.id, props?.uid, location, playlist, session]);
    const memoOpts = useMemo(() => {
        return {
            // suspense: true,
            ...swrOpts,
        };
    }, [swrOpts]);
    const { data, isValidating, mutate, error } = useSWR<
        ApiResponse<Struct.DeviceExpanded>
    >(props?.uid ? swrKey : null, DeviceFetch(abortController), memoOpts);

    return {
        data: data?.data,
        meta: data?.meta,
        type: data?.type,
        code: data?.code,
        isValidating,
        mutate,
        error,
        abortController: abortController?.current,
    };
}

export type UseDeviceProps = Props;
export default useDevice;
