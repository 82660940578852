import React, {useEffect, useMemo} from 'react';
import { Link, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import AuthenticatedRoute from '../components/AuthenticatedRoute';
import NotFound from '../components/NotFound';
import LoggedIn from "../components/LoggedIn";
import {UUID_PATTERN} from "@mtechvault/ams-types";

import WrappedFallback from '../errors/wrapped';
import {useTheme} from "@mui/material";
// import OrganizationRouter from './organization'
// const WrappedFallback = React.lazy(() => import('../errors/wrapped'));

const PortalPage = React.lazy(() => import('../pages/portal'));
const SigninPage = React.lazy(() => import('../pages/signin'));

const AccountPage = React.lazy(() => import('../pages/account'));
const RegisterPage = React.lazy(() => import('../pages/register'));
const LogoutPage = React.lazy(() => import('../pages/logout'));
const RecoverAccountPage = React.lazy(() => import('../pages/recoverAccount'));

const DeviceDownloads = React.lazy(() => import('../pages/organization/deviceDownloads'));

const InteractiveEmail = React.lazy(() => import('../pages/email/interact'));

const OrganizationRouter = React.lazy(() => import('./organization'));
const IntegrationsManager = React.lazy(() => import('../pages/integrationsManager'))

type Props = {};
function AppRouter(props: Props) {
    const theme = useTheme()
    const location = useLocation();
    const { t } = useTranslation(['organization', 'common']);
    // const [htmlTitle, setHtmlTitle] = useState(t('common:htmlTitle.default'))

    useEffect(() => {
        console.log({ location });
    }, [location])

    useEffect(() => {
        console.log({ theme })
    }, [theme])

    const htmlTitle = useMemo(() => {
        const parts = location.pathname
            .substr(1)
            .split('/')
            .filter((key, idx, arr) => {
                return (
                    key &&
                    ((!key.match(/^\d+$/) && !key.match(UUID_PATTERN)) ||
                        idx + 1 === arr.length)
                );
            })
            .map((key) => {
                if (key === 'o') return 'organization';
                if (key.match(/^\d+$/) || key.indexOf('%40') >= 0) return 'selected';
                return key;
            });

        parts.unshift('common:htmlTitle', 'pages');
        parts.push('title');

        return t(parts.join('.'), {
            defaultValue: t('common:htmlTitle.default'),
        });
    }, [location]);

    return (
        <ErrorBoundary fallback={<WrappedFallback />}>
            <Helmet title={htmlTitle} />
            <Routes location={location}>
                <Route
                    path={'/'}
                    element={<Navigate to={'/portal'} />}
                />

                <Route
                    path={'/portal2'}
                    element={(
                        <div>
                            Portal 2<br />
                            <Link to={'/portal'}>Portal</Link>
                        </div>
                    )}
                />

                <Route
                    path={'/portal/*'}
                    element={(
                        <LoggedIn redirect={'/signin'}>
                            <PortalPage />
                        </LoggedIn>
                    )}
                />

                <Route
                    path={'/downloads'}
                    element={<DeviceDownloads />}
                />


                <Route
                    path={'/o/:organizationId/*'}
                    element={(
                        <LoggedIn redirect={'/portal'}>
                            <OrganizationRouter />
                        </LoggedIn>
                    )}
                />

                <Route
                    path={'/account'}
                    element={(
                        <LoggedIn redirect={'/portal'}>
                            <AccountPage />
                        </LoggedIn>
                    )}
                />

                <Route
                    path={'/interactive/email/:emailUid'}
                    element={<InteractiveEmail />}
                />

                <Route
                    path={'/signin'}
                    element={<SigninPage />}
                />

                <Route
                    path={'/register'}
                    element={<RegisterPage />}
                />

                <Route path={'/forgot-password'} element={<RecoverAccountPage />} />

                <Route
                    path={'/integrate/:service'}
                    element={(
                        <LoggedIn redirect={'/portal'}>
                            <IntegrationsManager />
                        </LoggedIn>
                    )}
                />

                <Route
                    path={'/goodbye'}
                    element={<LogoutPage />}
                />

                {/*<Route element={<NotFound />} />*/}
            </Routes>
        </ErrorBoundary>
    );
}

export type AppRouterProps = Props;
export default AppRouter;
