import React, { ForwardedRef, useMemo } from 'react';
import AppBarComponent from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import {styled} from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import HouseOutlined from '@mui/icons-material/HouseOutlined';
import { DarkMode, WbSunny } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ThemeSelectorContext, Popover } from '@mtechvault/ams-client-base';

const Slogan = styled('span')(({ theme }) => ({
    display: 'none',
    marginLeft: '.5rem',
    fontSize: '.5em',
    [theme.breakpoints.up('sm')]: {
        display: 'inline',
    },
}));

const Logo = styled('img')(({ theme }) => ({
    height: 56,
    [`${theme.breakpoints.only('xs')} and (orientation: landscape)`]: {
        height: 48,
    },
    [theme.breakpoints.up('sm')]: {
        height: 64,
    },
    marginRight: theme.spacing(2),
}));

const Flexi = styled('div')({
    flexGrow: 1,
});

const SectionMobile = styled('div')(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.up('md')]: {
        display: 'none',
    },
}));

const SectionDesktop = styled('div')(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

type Props = {
    drawer?: boolean;
    toggleDrawer?: (evt) => unknown;
    minimal?: boolean;
    sticky?: boolean;
};

function AppBar(props: Props, inRef: ForwardedRef<HTMLDivElement>) {
    const { toggleDrawer, drawer, minimal, sticky } = props;
    const { t } = useTranslation(['common']);
    const theme = useTheme();
    const history = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        React.useState<null | HTMLElement>(null);
    const themeToggle = React.useContext(ThemeSelectorContext);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleMenuAccount = (evt: React.MouseEvent<HTMLElement>) => {
        handleMenuClose();
        history('/account');
    };

    const handleMenuLogout = (evt: React.MouseEvent<HTMLElement>) => {
        handleMenuClose();
        // logout();
        // reset();
        history('/goodbye');
    };

    const renderMenu = useMemo(() => {
        return (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem disabled onClick={handleMenuClose}>
                    {t('appbar.actions.profile')}
                </MenuItem>
                <MenuItem onClick={handleMenuAccount}>
                    {t('appbar.actions.account')}
                </MenuItem>
                <MenuItem onClick={handleMenuLogout}>
                    {t('appbar.actions.logout')}
                </MenuItem>
            </Menu>
        );
    }, [isMenuOpen, anchorEl, handleMenuClose, handleMenuAccount, handleMenuLogout]);

    const renderMobileMenu = useMemo(() => {
        return (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}
            >
                <MenuItem disabled>
                    <IconButton color="inherit" size="large">
                        <Badge badgeContent={4} color="secondary">
                            <MailIcon />
                        </Badge>
                    </IconButton>
                    <Typography>{t('appbar.actions.messages')}</Typography>
                </MenuItem>
                <MenuItem disabled>
                    <IconButton color="inherit" size="large">
                        <Badge badgeContent={11} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <Typography>{t('appbar.actions.notifications')}</Typography>
                </MenuItem>
                <MenuItem onClick={handleProfileMenuOpen}>
                    <IconButton color="inherit" size="large">
                        <AccountCircle />
                    </IconButton>
                    <Typography>{t('appbar.actions.profile')}</Typography>
                </MenuItem>
            </Menu>
        );
    }, [isMobileMenuOpen, handleProfileMenuOpen, handleMobileMenuClose]);

    return (
        <>
            <AppBarComponent
                position={'fixed'}
                // position={sticky ? 'fixed' : 'absolute'}
                sx={{ zIndex: theme.zIndex.appBar + 1 }}
            >
                <Toolbar>
                    <IconButton
                        onClick={toggleDrawer}
                        edge="start"
                        sx={{ marginRight: theme.spacing(2) }}
                        color="inherit"
                        aria-label="open drawer"
                        size="large"
                    >
                        {drawer ? (
                            <MenuIcon />
                        ) : (
                            <Popover title={'Go to portal'}>
                                <HouseOutlined />
                            </Popover>
                        )}
                    </IconButton>
                    <Logo src={'/logo/light.svg'} />
                    <Typography sx={{ display: 'block' }} variant="h4" noWrap>
                        <span>{t('appName')}</span>
                        <Slogan>{t('slogan')}</Slogan>
                    </Typography>
                    <Flexi />
                    {!Boolean(minimal) && (
                        <>
                            <SectionDesktop>
                                <Popover
                                    title={
                                        t(
                                            `appbar.actions.toggleTheme.${themeToggle.mode}`
                                        ) as string
                                    }
                                >
                                    <IconButton
                                        onClick={() => themeToggle.toggle(true)}
                                        color="inherit"
                                        size="large"
                                    >
                                        {themeToggle.mode === 'light' ? (
                                            <DarkMode />
                                        ) : (
                                            <WbSunny />
                                        )}
                                    </IconButton>
                                </Popover>
                                <IconButton disabled color="inherit" size="large">
                                    <Badge badgeContent={4} color="secondary">
                                        <MailIcon />
                                    </Badge>
                                </IconButton>
                                <IconButton disabled color="inherit" size="large">
                                    <Badge badgeContent={17} color="secondary">
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                                <IconButton
                                    edge="end"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                    size="large"
                                >
                                    <AccountCircle />
                                </IconButton>
                            </SectionDesktop>
                            <SectionMobile>
                                <IconButton
                                    onClick={handleMobileMenuOpen}
                                    color="inherit"
                                    size="large"
                                >
                                    <MoreIcon />
                                </IconButton>
                            </SectionMobile>
                        </>
                    )}
                </Toolbar>
            </AppBarComponent>
            {!Boolean(minimal) && renderMobileMenu}
            {!Boolean(minimal) && renderMenu}
        </>
    );
}

const forwardedBar = React.forwardRef(AppBar);
forwardedBar.defaultProps = {
    drawer: false,
    minimal: false,
    sticky: true,
} as Props;

export type AppBarProps = Props;
export default forwardedBar;
