import useSWR, { SWRConfiguration } from 'swr';
import { useMemo, useRef } from 'react';
import { ApiResponse, parsedEndpoint, Struct } from '@mtechvault/ams-types';
import useOrganization from './useOrganization';
import { PlaylistFetch } from '@mtechvault/ams-client-base';

type Props = Partial<SWRConfiguration> & {
    id?: number | null;
    content?: boolean;
    usage?: boolean;
};
function usePlaylist(props: Props = {}) {
    const { id: playlistId, usage, content, ...swrOpts } = props;
    const { current: organization } = useOrganization();
    const abortController = useRef<AbortController | null>(null);
    const swrKey = useMemo(() => {
        return parsedEndpoint('playlistGetOne', {
            organization: organization?.id,
            id: props?.id,
            content,
            usage,
        });
    }, [organization?.id, props?.id, content, usage]);
    const memoOpts = useMemo(() => {
        return {
            // suspense: true,
            ...swrOpts,
        };
    }, [swrOpts]);
    const { data, isValidating, mutate, error } = useSWR<
        ApiResponse<Struct.ExpandedPlaylist>
    >(props?.id ? swrKey : null, PlaylistFetch(abortController), memoOpts);

    return {
        data: data?.data,
        meta: data?.meta,
        type: data?.type,
        code: data?.code,
        isValidating,
        mutate,
        error,
        abortController: abortController?.current,
    };
}

export type UsePlaylistProps = Props;
export default usePlaylist;
