import React, {
    createContext,
    Dispatch,
    lazy,
    ReactNode,
    RefObject,
    SetStateAction,
    useMemo,
    useRef,
    useState,
} from 'react';
import {LoadingFallback} from '@mtechvault/ams-client-base';

const layouts = {
    minimal: lazy(() => import('./minimal')),
    main: lazy(() => import('./main')),
    organization: lazy(() => import('./organization')),
};

export type LayoutProps = {
    footer?: boolean;
    appbar?: boolean;
    drawer?: boolean;
    contentFallback?: ReactNode;
};

type LayoutOptions = keyof typeof layouts;

type Context = {
    layout: LayoutOptions;
    layoutProps: LayoutProps;
    setLayout: Dispatch<SetStateAction<LayoutOptions>>;
    setLayoutProps: Dispatch<SetStateAction<LayoutProps>>;
    layoutRef?: RefObject<HTMLDivElement>;
};

type Props = {
    layout?: LayoutOptions;
    layoutProps?: LayoutProps;
    children: ReactNode;
};

export const LayoutContext = createContext<Context>({
    layout: 'minimal',
    layoutProps: {},
} as never);
LayoutContext.displayName = 'LayoutContext';

function LayoutProvider(props: Props) {
    const [layout, setLayout] = useState<LayoutOptions>(
        props.layout ? props.layout : 'minimal'
    );
    const [layoutProps, setLayoutProps] = useState<LayoutProps>(
        props.layoutProps
            ? props.layoutProps
            : {
                  appbar: false,
              }
    );
    const layoutRef = useRef<HTMLDivElement | null>(null);

    const contextValue = useMemo(() => {
        return {
            layout,
            setLayout,
            layoutProps,
            setLayoutProps,
            layoutRef,
        };
    }, [layout, setLayout, layoutProps, setLayoutProps, layoutRef]) as Context;

    const Layout = useMemo(() => {
        return layouts[layout];
    }, [layout]);

    return (
        <LayoutContext.Provider value={contextValue}>
            <Layout
                ref={layoutRef}
                contentFallback={<LoadingFallback />}
                {...layoutProps}
            >
                {props.children}
            </Layout>
        </LayoutContext.Provider>
    );
}

export type LayoutProviderProps = Props;
export type LayoutContextValue = Context;
export default LayoutProvider;
