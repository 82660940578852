import React from 'react';
import {
    Box,
    Container as MuiContainer,
    Divider,
    Grid,
    Link,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import {Theme} from "@mui/material/styles";

type Props = any;

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
    transition: 'width .5s ease, margin-left .5s ease',
    [theme.breakpoints.up('md')]: {
        '&.drawer-open': {
            marginLeft: theme.drawerWidth,
            width: `calc(100% - ${theme.drawerWidth}px)`,
        },
    },
}));

const Logo = styled('img')(({ theme }) => ({
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
        margin: '0 0 0 auto',
    },
}));

const Container = styled(MuiContainer)(({ theme }) => ({
    padding: '1rem',
    background: theme.palette.background.paper,
}));

function Footer(props: Props) {
    const { className } = props;
    const { t } = useTranslation('common');
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Root className={className}>
            <Divider />
            <Container maxWidth={'xl'}>
                <Grid container spacing={2} alignItems={'flex-end'}>
                    <Grid item xs={12} md={4}>
                        <Typography
                            align={isDesktop ? 'left' : 'center'}
                            variant={'h5'}
                            gutterBottom
                        >
                            {t('footer.heading')}
                        </Typography>
                        <Typography
                            align={isDesktop ? 'left' : 'center'}
                            paragraph
                            variant={'body2'}
                        >
                            {t('footer.texts.0')}
                        </Typography>
                        <Typography
                            align={isDesktop ? 'left' : 'center'}
                            variant={'body2'}
                        >
                            {t('footer.texts.1')}
                        </Typography>
                    </Grid>
                    <Grid item md={4} />
                    <Grid item xs={12} md={4}>
                        <Box display={'flex'}>
                            <Logo
                                src={`/logo/${
                                    theme.palette.mode === 'light' ? 'dark' : 'light'
                                }.svg`}
                                width={150}
                            />
                        </Box>
                        <Typography
                            align={isDesktop ? 'right' : 'center'}
                            variant={'body2'}
                        >
                            <Trans
                                t={t}
                                i18nKey={'footer.version'}
                                values={{
                                    version: process.env.REACT_APP_VERSION || 'N/A',
                                }}
                            />
                        </Typography>
                        <Typography
                            align={isDesktop ? 'right' : 'center'}
                            variant={'body2'}
                        >
                            <Trans
                                t={t}
                                i18nKey={`footer.build.${process.env.REACT_APP_DEBUG_MODE}`}
                                values={{
                                    date:
                                        process.env.REACT_APP_DEBUG_MODE === 'development'
                                            ? new Date().toLocaleString()
                                            : new Date(
                                                  process.env
                                                      .REACT_APP_BUILD_DATE as string
                                              ).toLocaleString(),
                                    hash: process.env.REACT_APP_GIT_HASH,
                                }}
                            />
                        </Typography>
                        <Typography
                            align={isDesktop ? 'right' : 'center'}
                            variant={'body2'}
                            gutterBottom
                        >
                            <Trans
                                t={t}
                                i18nKey={'footer.bug'}
                                components={{
                                    Link: (
                                        <Link
                                            rel={'noreferrer'}
                                            href={`mailto:${t('helpEmail')}`}
                                        />
                                    ),
                                }}
                            />
                        </Typography>
                        <Typography
                            align={isDesktop ? 'right' : 'center'}
                            variant={'body2'}
                        >
                            <Trans
                                t={t}
                                i18nKey={'footer.copyright'}
                                components={{
                                    Link: (
                                        <Link
                                            target={'_blank'}
                                            rel={'noreferrer'}
                                            href={t('companyUrl')}
                                        />
                                    ),
                                }}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Root>
    );
}

export type FooterProps = Props;
export default Footer;
