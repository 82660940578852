import React, { ForwardedRef, ReactNode, useCallback } from 'react';
import { styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AppBar from '../components/AppBar';
import { LayoutProps } from './provider';
import Footer from '../components/Footer';

const Content = styled('div')(({ theme }) => ({
    position: 'relative',
    marginTop: 56,
    minHeight: 'calc(90vh - 56px)',
    transition: 'width .5s ease',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginTop: 64,
        minHeight: 'calc(90vh - 64px)',
    },
}));

type Props = LayoutProps & {
    children: ReactNode;
};

function MinimalLayout(props: Props, inRef: ForwardedRef<HTMLDivElement>) {
    const history = useNavigate();

    const onToggleDrawer = useCallback(() => {
        history('/portal');
    }, [history]);

    return (
        <>
            <AppBar minimal toggleDrawer={onToggleDrawer} />
            <Content ref={inRef}>
                <React.Suspense fallback={props.contentFallback || 'loading...'}>
                    {props.children}
                </React.Suspense>
            </Content>
            {props.footer && <Footer />}
        </>
    );
}

const forwarded = React.forwardRef(MinimalLayout);

forwarded.defaultProps = {
    footer: true,
};

export type MinimalLayoutProps = Props;
export default forwarded;
