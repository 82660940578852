import i18next, { Resource } from 'i18next';
import i18nBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import interpolation from './interpolation';
import common from './locales/en/common.json';
import organization from './locales/en/organization.json';

const resources: Resource = {
    en: {
        common, // : import('./locales/en/common.json'),
        organization,
    },
};

function init() {
    return i18next
        .use(initReactI18next)
        .use(i18nBackend)
        .init({
            // debug: true,
            fallbackNS: false,
            fallbackLng: false,
            defaultNS: 'common',
            supportedLngs: ['en'],
            // load: 'currentOnly',
            lng: 'en',
            ns: ['common', 'organization'],
            resources,
            react: {
                transKeepBasicHtmlNodesFor: ['b', 'br', 'u', 'i', 'span'],
            },
            backend: {
                loadPath: '/api/v2/locale/{{lng}}/{{ns}}.json',
                // parse(data: string, languages?: string | string[], namespaces?: string | string[]) {
                //     console.log(data, languages, namespaces)
                //     return {}
                // },
            },
            interpolation,
        });
}

export default init;
