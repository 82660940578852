import React, {ReactNode, useEffect, useMemo, useRef} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {useUser} from "../../hooks";
import {Navigate, Route, useNavigate} from "react-router-dom";
import {PageContainer} from '@mtechvault/ams-client-base';
import {Alert, Button, Stack, Typography} from "@mui/material";

type Props = {
    redirect?: string;
    unauthorizedComponent?: ReactNode;
    children: ReactNode;
}

function LoggedIn(props: Props) {
    const { redirect, unauthorizedComponent, children } = props;
    const navigate = useNavigate()
    const { isValidating, isLoggedIn, error, mutate } = useUser({
        // suspense: true,
    });

    const renderedErrorComponent = useMemo(() => {
        if (error) {
            if (!!unauthorizedComponent)
                return unauthorizedComponent
            return <div />
        }
        return null
    }, [error, unauthorizedComponent])

    useEffect(() => {
        if (!error && !isLoggedIn && !isValidating) {
            mutate()
        } else if (error && !!redirect && !isValidating) {
            navigate(redirect)
        }
    }, [isLoggedIn, isValidating, error])

    return (
        <>
            {(isValidating || !isLoggedIn)
                ? (<div>loading...</div>)
                : renderedErrorComponent
                    ? renderedErrorComponent
                    : children
            }
        </>
    );
}


function withErrorBoundary(Fn: typeof LoggedIn): typeof LoggedIn {
    return (props: Props) => {
        const errorBoundaryRef = useRef<ErrorBoundary | null>(null);

        // const errorBoundary = useMemo(() => {
        //     console.log('unobject', error)
        //     if (typeof error !== 'object') {
        //         return null;
        //     };
        //     if (error.error === -10010) {
        //         // user not logged in
        //         return <Navigate to={'/signin'} />;
        //     }
        //
        //     return (
        //         <PageContainer>
        //             <Alert color={'error'}>
        //                 <Stack alignItems={'flex-start'} gap={1}>
        //                     <Typography>
        //                         An error occurred while loading the page.
        //                     </Typography>
        //                     <Button
        //                         variant={'outlined'}
        //                         color={'error'}
        //                         onClick={() => window.location.reload()}
        //                     >
        //                         Reload Page
        //                     </Button>
        //                 </Stack>
        //             </Alert>
        //         </PageContainer>
        //     );
        // }, [error]);

        return (
            <ErrorBoundary fallback={<div>todo</div>} ref={errorBoundaryRef}>
                <Fn {...props} />
            </ErrorBoundary>
        );
    };
}


export type LoggedInProps = Props;
export default withErrorBoundary(LoggedIn);
// export default LoggedIn;
