import useSWR from 'swr';
import { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ApiError, ApiResponse, EmailResponse, parsedEndpoint } from '@mtechvault/ams-types';
import { FetchInteractiveEmail } from '@mtechvault/ams-client-base';
import { useQuery } from '@mtechvault/ams-client-base'

type Params = {
    emailUid: string;
};

type Query = {
    id: string;
};

type Props = {};

function useEmail(props: Props = {}) {
    const abortController = useRef<AbortController | null>(null);
    const { emailUid: id } = useParams<Params>();
    const { id: verification } = useQuery<Query>();
    const swrKey = useMemo(
        () => [
            parsedEndpoint('interactiveEmail', {
                id,
                verification,
            }),
        ],
        [id, verification]
    );
    const { data, isValidating, mutate, error } = useSWR<
        ApiResponse<EmailResponse>,
        ApiError
    >(swrKey, FetchInteractiveEmail(abortController), {
        revalidateOnFocus: false,
        revalidateIfStale: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
        shouldRetryOnError: false,
    });

    return {
        data: data?.data,
        meta: data?.meta,
        type: data?.type,
        code: data?.code,
        message: data?.message,
        isValidating,
        mutate,
        error,
        abortController: abortController?.current,
    };
}

export type UseEmailProps = Props;
export default useEmail;
