import { useMemo, useRef } from 'react';
import useSWR, { SWRConfiguration } from 'swr';
import { ApiError, ApiResponse, parsedEndpoint, Struct } from '@mtechvault/ams-types';
import {
    HandleUserOrganizationInvitation,
    UserOrganizationInvitations,
} from '@mtechvault/ams-client-base';
import { usePromise } from '@mtechvault/ams-client-base';

type Props = Partial<SWRConfiguration> & {};

function useOrganizationInvitation(props: Props = {}) {
    const { ...swrOpts } = props;
    const abortController = useRef<AbortController | null>(null);
    const interactAbortController = useRef<AbortController | null>(null);
    const swrKey = useMemo(() => {
        return parsedEndpoint('userInvitations');
    }, []);
    const memoOpts = useMemo(() => {
        return {
            // suspense: true,
            ...swrOpts,
        };
    }, [swrOpts]);
    const { data, isValidating, mutate, error } = useSWR<
        ApiResponse<Struct.UserInvitation[]>,
        ApiError
    >(swrKey, UserOrganizationInvitations(abortController), memoOpts);

    // const interactArgs = useMemo(() => ([
    //     parsedEndpoint()
    // ]), []);
    const respondToInvite = usePromise<ApiResponse, ApiError>(
        HandleUserOrganizationInvitation(interactAbortController),
        {
            // args: interactArgs
        }
    );

    return {
        data: data?.data,
        meta: data?.meta,
        type: data?.type,
        code: data?.code,
        isValidating,
        mutate,
        error,
        respondToInvite,
        abortController: abortController?.current,
    };
}

export type UseOrganizationInvitationProps = Props;
export default useOrganizationInvitation;
