import { adaptV4Theme, alpha, createTheme, darken, darkScrollbar } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { CSSProperties } from '@mui/material/styles/createMixins';

declare module '@mui/material/styles/createMixins' {
    export interface Mixins {
        stickyTop: (theme: Theme) => CSSProperties;
        fancyScrollbar: (theme: Theme) => CSSProperties;
    }
}

export const buildTheme: (mode: 'light'|'dark') => Theme = (mode: 'light' | 'dark') => createTheme({
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: mode === 'dark' ? darkScrollbar() : null,
                }
            },
            MuiAppBar: {
                defaultProps: {
                    enableColorOnDark: true,
                },
            },
        },
            zIndex: {
                appBar: 1200,
                drawer: 1100,
            },
        palette: {
            mode,
            primary: {
                main: '#3f51b5',
            },
            secondary: {
                main: '#de32e2',
            },
        },
        typography: {
            h1: {
                fontSize: '2.5rem',
            },
            h2: {
                fontSize: '2.25rem',
            },
            h3: {
                fontSize: '2rem',
            },
            h4: {
                fontSize: '1.75rem',
            },
            h5: {
                fontSize: '1.5rem',
            },
        },
        spacing: 8,
        shape: {
            borderRadius: 4,
        },
        mixins: {
            stickyTop: (theme: Theme) => ({
                position: 'sticky',
                top: 56,
                zIndex: 200,
                [`${theme.breakpoints.only('xs')} and (orientation: landscape)`]: {
                    top: 48,
                },
                [theme.breakpoints.up('sm')]: {
                    top: 64,
                },
            }),
            fancyScrollbar: (theme: Theme) => ({
                '&::-webkit-scrollbar': {
                    width: '.5em',
                },
                '&::-webkit-scrollbar-track': {
                    boxShadow: `inset 0 0 6px ${alpha(theme.palette.divider, 0.3)}`,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: darken(theme.palette.divider, 0.2),
                    // outline: '1px solid slategrey'
                },
            }),
        }
        },
        {
            drawerWidth: 300,
        }
    );
