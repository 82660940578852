import useSWR, { SWRConfiguration } from 'swr';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ApiResponse, Pagination, parsedEndpoint, Struct } from '@mtechvault/ams-types';
import useOrganization from './useOrganization';
import { PlaylistListFetch } from '@mtechvault/ams-client-base';
import { useUniqueId, useKeyboard } from '@mtechvault/ams-client-base'

export type PlaylistSelector = 'active' | 'inactive' | undefined;

type InternalPage = Pick<Pagination<Struct.Playlist>, 'after' | 'before'>;

type Props = {
    pagination: Omit<Pagination<Struct.Playlist>, 'after' | 'before' | 'page'> & {
        selector?: PlaylistSelector;
        usage?: boolean;
        content?: boolean;
        listMode?: 'full' | 'select';
    };
    config?: SWRConfiguration;
    enableKeyboard?: boolean;
};

type ThisOrThat<Flag extends boolean, A, B> = [Flag] extends true ? A : B;

function usePagedPlaylists<Expanded extends boolean = false>(
    props: Props = { pagination: {} }
) {
    const { pagination: otherProps, enableKeyboard, config } = props;
    const internalId = useUniqueId('paged-playlists');
    const { current: organization } = useOrganization();
    const abortController = useRef<AbortController | null>(null);
    const pageIndex = useRef(0);
    const pageRefs = useRef<Array<Pagination>>([{}]);
    const [internalPage, setInternalPage] = useState<InternalPage>({});
    const swrKey = useMemo(() => {
        return [
            parsedEndpoint('playlistGetMany', {
                organization: organization?.id,
                key: otherProps.key,
                desc: otherProps.desc,
                like: otherProps.like,
                limit: otherProps.limit,
                selector: otherProps.selector,
                content: otherProps.content,
                usage: otherProps.usage,
                listMode: otherProps.listMode,
                after: internalPage.after,
                before: internalPage.before,
            }),
        ];
    }, [
        organization?.id,
        otherProps.selector,
        otherProps.content,
        otherProps.listMode,
        otherProps.usage,
        otherProps.key,
        otherProps.desc,
        otherProps.like,
        otherProps.limit,
        internalPage.after,
        internalPage.before,
    ]);

    const memoOpts: SWRConfiguration = useMemo(() => {
        return { ...(config || {}) };
    }, [config]);

    const { data, isValidating, mutate, error } = useSWR<
        ApiResponse<ThisOrThat<Expanded, Struct.Playlist[], Struct.ExpandedPlaylist[]>>
    >(
        organization ? swrKey : null,
        PlaylistListFetch<Expanded>(abortController),
        memoOpts
    );

    const hasNext = useMemo(() => {
        return (otherProps.limit || 10) <= (data?.data.length || 0);
    }, [otherProps.limit, data?.data?.length]);

    const hasPrev = useMemo(() => pageIndex.current > 0, [swrKey]);

    const next = useCallback(() => {
        pageIndex.current += 1;
        if (
            pageRefs.current.findIndex(
                (i: Pagination) =>
                    i.before === (otherProps.desc ? data?.meta?.last : undefined) &&
                    i.after === (!otherProps.desc ? data?.meta?.last : undefined)
            ) === -1
        ) {
            pageRefs.current.push({
                ...prev,
                before: otherProps.desc ? data?.meta?.last : undefined,
                after: !otherProps.desc ? data?.meta?.last : undefined,
            });
        }

        // if (isValidating && abortController.current) {
        //     abortController.current.abort()
        // }

        setInternalPage(() => (pageRefs.current[pageIndex.current] as never) || {});
    }, [setInternalPage, data?.meta?.last, isValidating]);

    const prev = useCallback(() => {
        if (pageIndex.current <= 0) return;

        // if (isValidating && abortController.current) {
        //     abortController.current.abort()
        // }

        pageIndex.current -= 1;
        setInternalPage(() => (pageRefs.current[pageIndex.current] as never) || {});
    }, [setInternalPage, isValidating]);

    const keyboardHandler = useCallback(
        (evt) => {
            if (evt.key === 'ArrowRight') {
                if (hasNext) next();
            }
            if (evt.key === 'ArrowLeft') {
                if (hasPrev) prev();
            }
        },
        [hasNext, hasPrev, next, prev]
    );

    useKeyboard(internalId, enableKeyboard ? keyboardHandler : null);

    useEffect(() => {
        pageIndex.current = 0;
        pageRefs.current = [{}];
        setInternalPage(() => (pageRefs.current[pageIndex.current] as never) || {});
        // if (abortController.current) {
        //     abortController.current.abort()
        // }
    }, [
        otherProps.desc,
        otherProps.key,
        otherProps.like,
        otherProps.limit,
        otherProps.selector,
    ]);

    return {
        data: data?.data,
        meta: data?.meta,
        type: data?.type,
        code: data?.code,
        isValidating,
        mutate,
        // mutate: handleMutate,
        error,
        hasNext,
        hasPrev,
        next,
        prev,
        abortController: abortController.current,
    };
}

export type UsePagedPlaylistsProps = Props;
export default usePagedPlaylists;
